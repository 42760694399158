import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CharacterCount from "@tiptap/extension-character-count";
import { Editor, EditorContent } from "@tiptap/vue-2";
import MenuBar from "./MenuBar.vue";
import Image1 from "@tiptap/extension-image";
import History from "@tiptap/extension-history";
export default {
  data: () => ({
    seoAirlines: {
      id: 0,
      code: "",
      name: "",
      url_slug: "",
      body: "",
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      logo_alt_text: "",
      og_tc_same_to_meta: false,
      head_scripts: "",
      body_scripts: "",
      logo_url: "",
      cities: [],
      faqs: [{ question: "", answer: "" }],
    },
    selectedAirlines: {
      code: "",
      name: "",
      ratings: "",
      alliance: "",
      rectangular_logo_url: "",
      rectangular_white_logo_url: "",
      square_logo_url: "",
      tail_logo_url: "",
    },
    companyList: [],
    airline: "",
    editor: null,
    isFullScreen: false,
    loading: false,
    webUrl: process.env.VUE_APP_WEB_URL,
    airlinesList: [],
    selectedAirline: [],
    cityList: [],
    selectedCity: [],
    // regionsList: [],
    factsList: [],
    selectedRows: [],
    showAddFact: false,
    selectedFactIndex: null,
    company_id: '',
    showAddFaq: false,
    selectedFaqIndex: null,
    faqsList: [],
    error: "",
    backUrl: "/seo-airlines",
  }),
  components: {
    Multiselect,
    EditorContent,
    MenuBar,
  },
  watch: {
    "seoAirlines.og_tc_same_to_meta": function (value) {
      if (value) {
        this.seoAirlines.og_tc_title = this.seoAirlines.meta_title;
        this.seoAirlines.og_tc_description = this.seoAirlines.meta_description;
      } else {
        this.seoAirlines.og_tc_title = this.seoAirlines.og_tc_title;
        this.seoAirlines.og_tc_description = this.seoAirlines.og_tc_description;
      }
    },
    company_id: {
      handler(value) {
        if (value) {
          this.selectedAirlines = {};
        } else {
          this.selectedAirlines = {};
        }
      }
    },
    selectedAirlines: {
      handler(newVal) {
        if (this.$route.params.id == null) {
          this.seoAirlines.code = newVal.code;
          this.seoAirlines.name = newVal.name;
          this.seoAirlines.ratings = newVal.ratings;
          this.seoAirlines.alliance = newVal.alliance;
          this.seoAirlines.rectangular_logo_url = newVal.rectangular_logo_url;
          this.seoAirlines.rectangular_white_logo_url =
            newVal.rectangular_white_logo_url;
          this.seoAirlines.square_logo_url = newVal.square_logo_url;
          this.seoAirlines.tail_logo_url = newVal.tail_logo_url;
        }
      },
      deep: true,
    },
  },
  computed: {
    isSameOgTcValue() {
      return this.seoAirlines.og_tc_same_to_meta ? 1 : 0;
    },
    getRules() {
      return this.seoAirlines.og_tc_image ? "" : "required";
    },
    getDataTitle() {
      return this.imageName ? this.imageName : "Drag your image here";
    },
    wordCount() {
      if (this.seoAirlines.post_body.trim() === "") {
        return 0;
      }
      return this.seoAirlines.post_body.trim().split(/\s+/).length;
    },
    calculateReadingTime1() {
      const wordsPerMinute = 150;
      const minutes = Math.ceil(this.wordCount / wordsPerMinute);
      return minutes;
    },
    getregion() {
      this.code = this.seoAirlines.code;
      this.name = this.seoAirlines.name;
      return this.code + "-" + this.name;
    },
  },
  methods: {
    updateIsSameOgTcValue(event) {
      this.seoAirlines.og_tc_same_to_meta = event.target.checked;
    },
    cloneItem(index) {
      const newItem = {
        question: "",
        answer: "",
      };
      this.seoAirlines.faqs.push(newItem);
    },
    removeItem(index) {
      if (this.seoAirlines.faqs.length > 1) {
        this.seoAirlines.faqs.splice(index, 1);
      }
    },
    calculateReadingTime(wordCount) {
      const wordsPerMinute = 150;
      const minutes = Math.ceil(wordCount / wordsPerMinute);
      return minutes;
    },
    calculateReadingTimeLabel(wordCount) {
      const minutes = this.calculateReadingTime(wordCount);
      return minutes === 1 ? "minute" : "minutes";
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      const editorElement = document.querySelector(".editor");
      if (editorElement) {
        if (this.isFullScreen) {
          editorElement.style.height = "100vh";
        } else {
          editorElement.style.height = "26rem";
        }
      }
    },
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
    initEditor() {
      this.seoAirlines.body = "";

      this.editor = new Editor({
        content: this.seoAirlines.body,
        extensions: [
          StarterKit.configure({
            history: false,
          }),
          Link.configure({
            openOnClick: false,
          }),
          CharacterCount,
          BulletList.configure({
            itemTypeName: "listItem",
            keepMarks: true,
            keepAttributes: true,
          }),
          ListItem,
          OrderedList,
          Image1,
          History,
        ],
        onUpdate: () => {
          this.seoAirlines.body = this.editor.getHTML();
          this.$emit("input", this.seoAirlines);
        },
      });
    },
    resetForm() {
      this.$refs.observer.reset();
      this.seoAirlines = {
        id: 0,
        title: "",
        name: "",
        body: "",
        page_title: "",
        url_slug: "",
        head_section: "",
        body_section: "",
        priority: "",
      };
    },
    validateFaqs() {
      Number.isInteger(this.selectedFaqIndex)
        ? this.updateFaqs()
        : this.addFaqs();
    },

    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        _vm.seoAirlines.logo_url = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    regionCreateOrUpdate() {
      let _vm = this;
      const com_id = _vm.company_id;
      let fd = new FormData();
      _vm.seoAirlines.cities = JSON.stringify(_vm.selectedCity);
      _vm.seoAirlines.company_id = _vm.company_id;
      if (_vm.seoAirlines.logo_url) {
        fd.append("logoImage", _vm.seoAirlines.logo_url);
      }
      _vm.seoAirlines.og_tc_same_to_meta = _vm.seoAirlines.og_tc_same_to_meta
        ? 1
        : 0;
      _vm.seoAirlines.body = _vm.editor.getHTML();
      _vm.seoAirlines.url_slug = _vm.seoAirlines.url_slug.toLowerCase();

      Object.keys(_vm.seoAirlines).forEach((key) => {
        if (_vm.seoAirlines[key] !== null) {
          // Exclude fields with null value
          if (key === "faqs") {
            _vm.seoAirlines.faqs.forEach((faq, index) => {
              fd.append(`faqs[${index}][question]`, faq.question);
              fd.append(`faqs[${index}][answer]`, faq.answer);
            });
          } else {
            fd.append(key, _vm.seoAirlines[key]);
          }
        }
      });

      this.axios.post(`/seo/airlines/` + com_id + `/update`, fd).then(function (response) {
        _vm.$router.push(_vm.backUrl);
      });
    },

    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/seo/airlines/" + this.$route.params.company_id + '/' + this.$route.params.id)
        .then(function (response) {
          _vm.seoAirlines = response.data.data;

          _vm.seoAirlines.body = response.data.data.body;
          _vm.value(_vm.seoAirlines.body);

          _vm.selectedCity = _vm.seoAirlines.cities;
          _vm.company_id = _vm.seoAirlines.company_id;

          if (
            _vm.seoAirlines.full_image_url &&
            _vm.seoAirlines.full_image_url.length > 0
          ) {
            $("#imagePreview").css(
              "background-image",
              "url(" +
              _vm.seoAirlines.full_image_url +
              "?date=" +
              Date.now() +
              ")"
            );
            $("#imagePreview").hide();
            $("#imagePreview").fadeIn(650);
          }
        })
        .catch(function () { });
    },

    getCity() {
      let _vm = this;
      this.axios
        .get("/city-by-city-code-list", _vm.cityList)
        .then(function (response) {
          _vm.cityList = response.data.data;
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail();
          }
        })
        .catch(function () { });
    },

    // closeFactForm(e) {
    //   e.preventDefault();
    //   document.getElementById("loginform").style.display = "none";
    //   this.showAddFact = false;
    // },
    // closeFaqForm(e) {
    //   e.preventDefault();
    //   document.getElementById("loginform1").style.display = "none";
    //   this.showAddFaq = false;
    // },
    updateUrl(event) {
      if (event.code === "Space") {
        const inputText = event.target.value
          .replace(/\s{1,}/g, "-")
          .toLowerCase();
        if (
          this.seoAirlines.url_slug.length > 1 &&
          this.seoAirlines.url_slug[this.seoAirlines.url_slug.length - 2] ===
          "-"
        ) {
          this.seoAirlines.url_slug = inputText;
        } else {
          this.seoAirlines.url_slug = inputText;
        }
      }
    },
    getAirlinesCode() {
      let _vm = this;
      const com_id = _vm.company_id;

      this.axios
        .get(`/seo/airlines/` + com_id + `/` + `distinct-list`)
        .then(function (response) {
          _vm.airlinesList = response.data.data;
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail();
          }
        })
        .catch(function () { });
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.company_id = _vm.company_id ? _vm.company_id : _vm.companyList[0].id;
          _vm.getAirlinesCode();
        })
        .catch(function () { });
    },
    customLabel({ city, city_code }) {
      return `${city} - ${city_code}`;
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== "") {
      this.getDetail();
    }

    // this.getAirlinesCode();
    this.getCity();
    this.getCompany();
    window.onload = function () {
      document.getElementById("factsaddbtn").click();
    };
    this.initEditor();
  },
};
